var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticStyle:{"background-color":"#f2f2f2 !important","min-height":"100%"},attrs:{"fluid":""}},[_c('v-row',{staticClass:"ma-0 pa-0 px-2 mb-2",staticStyle:{"background-color":"white !important","border":"1px solid teal","border-radius":"6px"}},[_c('v-col',{staticClass:"ma-0 pa-0 py-2 mr-2",attrs:{"cols":"12"}},[_c('v-card',{staticStyle:{"background-color":"transparent"},attrs:{"flat":""}},[_c('v-card-title',{staticClass:"pa-0"},[_c('date-range-picker',{attrs:{"initDateMode":"thisWeek"},on:{"dateChange":_vm.onDateChange}}),_c('v-spacer'),_c('v-btn',{staticClass:"ma-2 pa-1 px-2",attrs:{"outlined":"","color":_vm.showCalendar ? '#AD1457' : 'grey',"min-width":"0"},on:{"click":function($event){_vm.showCalendar = !_vm.showCalendar}}},[_c('v-icon',[_vm._v("mdi-calendar-clock")])],1),_c('div',{class:{
              'ml-0 mt-0': _vm.$vuetify.breakpoint.smAndDown,
              'ml-1': _vm.$vuetify.breakpoint.mdAndUp,
            },staticStyle:{"width":"100%"}},[_c('filter-array',{attrs:{"filters":_vm.statusList},on:{"change-filters":_vm.updateFilter}})],1)],1)],1)],1)],1),_c('v-row',[_c('v-col',{class:{
        'd-none': !_vm.allowShowCalendar,
      },attrs:{"cols":"12","md":"12"}},[_c('mcv-calendar',{attrs:{"data":_vm.displayCalendar,"allowShowEvent":true}})],1),_c('v-col',{class:{
        'd-none': !_vm.allowShowTable,
      },attrs:{"cols":"12"}},[_c('v-card',{staticStyle:{"border-radius":"8px","min-height":"320px"},attrs:{"flat":""}},[_c('v-card-title',{staticClass:"py-2 px-2 mb-1",staticStyle:{"color":"teal"},attrs:{"dark":""}},[_c('v-btn',{staticClass:"text-none mr-2 mb-2",attrs:{"color":"teal","dark":"","to":"/off-submit-order"}},[_c('v-icon',{staticClass:"mr-2",attrs:{"small":""}},[_vm._v("mdi-plus")]),_vm._v(" Xin Nghỉ Phép ")],1),[_c('v-btn',{staticClass:"text-none mr-2 mb-2",staticStyle:{"color":"white"},attrs:{"color":"error","light":"","disabled":!_vm.allowCancel},on:{"click":function($event){return _vm.callCancelSession()}}},[_c('v-icon',{staticClass:"mr-2",attrs:{"small":""}},[_vm._v("mdi-close-octagon")]),_vm._v(" Hủy ")],1)],_c('v-spacer'),_c('v-text-field',{staticStyle:{"max-width":"320px"},attrs:{"append-icon":"mdi-magnify","label":"Search","clearable":"","outlined":"","dense":"","single-line":"","hide-details":""},model:{value:(_vm.searchString),callback:function ($$v) {_vm.searchString=$$v},expression:"searchString"}})],2),_c('v-card-text',{staticClass:"pa-0 pt-2",staticStyle:{"min-height":"300px"}},[_c('v-data-table',{staticClass:"elevation-0",attrs:{"flat":"","headers":_vm.headers,"items":_vm.sessionsAfterFilter,"loading":_vm.loading,"loading-text":"Loading... Please wait","no-data-text":"Chưa có phòng nào","item-key":"_id","mobile-breakpoint":0,"items-per-page":_vm.pageSize,"show-select":"","single-select":"","dense":"","search":_vm.searchString,"page":_vm.depsPage,"hide-default-footer":"","sort-by":['start_time'],"sort-desc":[true]},on:{"update:page":function($event){_vm.depsPage=$event},"page-count":function($event){_vm.pageCount = $event}},scopedSlots:_vm._u([{key:"item.start_time",fn:function(ref){
      var item = ref.item;
return [_c('div',{staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.gotoSession('/off-sessions/' + item._id)}}},[_vm._v(" "+_vm._s(_vm.resolveOfDateDisplay(item))+" ")])]}},{key:"item.props",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getOffOrderStatusName(item.props.offType, item.props.pickedHours))+" ")]}},{key:"item.status",fn:function(ref){
      var item = ref.item;
return [_c('div',{staticStyle:{"font-size":"12px","font-weight":"600"},style:({'color': _vm.getStatusColor(item.status)})},[_vm._v(" "+_vm._s(_vm.getStatusName(item.status))+" ")])]}}]),model:{value:(_vm.selected_sessions),callback:function ($$v) {_vm.selected_sessions=$$v},expression:"selected_sessions"}}),(_vm.pageCount > 1)?_c('div',{staticClass:"text-center pt-2"},[_c('v-pagination',{attrs:{"length":_vm.pageCount},model:{value:(_vm.depsPage),callback:function ($$v) {_vm.depsPage=$$v},expression:"depsPage"}})],1):_vm._e()],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }